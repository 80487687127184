
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Popover from 'react-bootstrap/Popover';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { UiMetricGrid } from './UiMetricGrid';
import { KpiFormulaEdit} from './KpiFormulaEdit'

import React, { useState,  useEffect, StrictMode } from "react";


let url_server = process.env.REACT_APP_DCI_UTIL_URL 
if (process.env.REACT_APP_DCI_UTIL_PORT != "80") {
  url_server = url_server + ":" + process.env.REACT_APP_DCI_UTIL_PORT
}


const kpiInfo =
<Popover id="popover-kpiinfo">
<Popover.Header as="h3">KPI Information</Popover.Header>
<Popover.Body>
  We are going to have information about the KPI here!
</Popover.Body>
</Popover>


async function loadKpiList(smd, smr) {
// loads a warehouse plan with the designated higlighting
// smr == function that is told false when loading and true when loaded
// smd == function that is passed the result of the api lookup

  smr(false)
  
  const url = url_server + "/kpiAPI/1/kpilist"

  let response = await fetch(url,
      {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
}
      });
  let rsp = await response.json();
  smd(rsp);
  smr(true)
};

async function loadKpiDetail(sd,kpi, smd) {
  // loads a warehouse plan with the designated higlighting
  // smr == function that is told false when loading and true when loaded
  // smd == function that is passed the result of the api lookup
  
    sd(false)
    
    const url = url_server + "/kpiAPI/1/kpidefn" + "?kpi=" + kpi.toString()
  
    let response = await fetch(url,
        {
        method: "GET",
        mode: "cors",
        headers: {
          "Accept": "application/json",
}
        });
    let rsp = await response.json();
    smd(rsp);
    sd(true)
  };

  async function deleteKPITag(kpi, tag,dltTagList) {
    // loads a warehouse plan with the designated higlighting
    // smr == function that is told false when loading and true when loaded
    // smd == function that is passed the result of the api lookup
      
      const url = url_server + "/kpiAPI/1/tagKpi" + "?kpi=" + kpi.toString() + "&tag=" + tag.toString()
    
      let response = await fetch(url,
          {
          method: "DELETE",
          mode: "cors",
          headers: {
            "Accept": "application/json",
  }
          });
      let rsp = await response.json();
      dltTagList(tag)
    };
  

function MetricDetail(props) {
  console.log("detail = ", props.detail)

  const [tagList,setTagList] = useState(props.detail.TAGS);
  const [nTagList,setNTagList] = useState("GO");

  useEffect(() => {
    if (nTagList != "GO") {
      setTagList(nTagList)
    }
  },[nTagList])

  const dltTagList = (tag) => 
  {
    let tl = tagList
    let ix = 0
    let rmvInx = -1  
    console.log("looking to delete")
    tl.map((tg) => {
      console.log("checking tag ", tg.KPI_TAG_ID )
      if (tg.KPI_TAG_ID = tag) {
          console.log("got a match", ix, tag)
          rmvInx = ix
      }
      ix++
    })

    if (rmvInx > -1){
      console.log("doing splice", tl)
      tl.splice(rmvInx,1)
      console.log("done splice", tl)
      setNTagList(tl)
    }
  }

  const editFormula = (props) => (
  <Card style={{width: '25rem'}}>
  <Card.Body>
    <Card.Title>{props.detail.KPI_NAME}</Card.Title>
    <KpiFormulaEdit></KpiFormulaEdit>
  </Card.Body>
  </Card>
  )

  return (

    <>
    <Card>
      <Card.Body>
        <Card.Title>{props.detail.KPI_NAME}</Card.Title>
      <br></br>
        <Table>
        <tbody>
        <tr>
          <td>Metric Identifier</td><td>{props.detail.KPI_IDENTIFIER}</td></tr>
        <tr><td>Dimension Name</td><td>{props.detail.KPI_DIM01_NAME}</td></tr>
        </tbody>
        </Table> 

    <br></br>  
    <Tabs>
      <Tab eventKey= "Tags" title="Tags">
      <br></br>
      <Table>
        <tbody>
          {tagList.map((b) => 
            <>
              <tr>
              <td><Image src={'icons/'+b.TAG_ICON} width="30px" height="30px" ></Image></td><td>{b.KPI_TAG_NOTE}</td>
              <td><Button fluid variant="outline-light" onClick={(e) => deleteKPITag(props.detail.KPI_IDENTIFIER,b.KPI_TAG_ID,dltTagList)}>
                <Image src='icons/trash3.svg' width="15px" height="15px"></Image>
              </Button></td></tr></>
          )}
        </tbody>
      </Table>
      </Tab>

      <Tab eventKey= "Benchmarks" title="Benchmarks">
      <br></br>
      <Table>
        <tbody>
          {props.detail.BASELINES.map((b) => 
            <tr>
              <td><Button fluid variant="outline-light"><Image src='icons/trash3.svg' width="15px" height="15x"></Image></Button></td>
              <td>{b.KPI_BASELINE_DESC}</td><td>{b.KPI_DIM01}</td><td>{b.KPI_BASELINE}</td></tr>
          )}
        </tbody>
      </Table>
    </Tab>
    <Tab eventKey= "Calculation" title="Calculation">
      <br></br>
      <KpiFormulaEdit></KpiFormulaEdit>

      
    </Tab>
    <Tab eventKey= "Info" title="Info">
      <br></br>
    </Tab>


    </Tabs>
    </Card.Body>
    </Card>
    </>
) }


function KpiConfigCtl(props) {
  const [kpi,setKpi] = useState("None");
  const [kpiList,setKpiList] = useState("WAIT");
  const [kpiListFlag,setKpiListFlag] = useState(false);
  const [showGrid,setShowGrid] = useState(false);
  const [showDetail,setShowDetail] = useState(false);
  const [firstTime, setFirstTime] = useState(true)
  const [sMetric, setMetric] = useState("")
  const s= {topicon: {width:"18px", height:"18px"},
            addicon: {width:"25px", height:"25px"}}
  const [sDetail,setDetail] = useState("");
  const sml = (q) => { {setKpiList(q); console.log("setting KpiList",q)} }
  const srl = (q) => { {setKpiListFlag(q); setShowGrid(q); console.log("setting KPIList Render Flag",q)}}
  const chtyp = (q) => { { console.log("Pressed the button ",q)}}

  // get list of KPIs 
  useEffect(() => {
    if (firstTime == true){
      loadKpiList(sml,srl);
      setFirstTime(false);
    }
  },[firstTime])

  useEffect(() => {
    console.log("MMetric = ", sMetric)
    loadKpiDetail(setShowDetail, sMetric, setDetail)
  },[sMetric])


  const filterTag = (params) => {
    return ( (params.value.length > 0) ? params.value[0].name : "" )
  }

  const tableCols = [

    { field: 'Metric', headerName: 'Measure', width: 100 },
    { field: 'Description', headerName: 'Description', width: 300 },
    { field: 'Tags', headerName: 'Tags', width: 200, 
    renderCell: (params) => (
        <>
        { (params.value.length > 0) ? 
        <img src={'icons/'+params.value[0].icon} height="20" width="20"></img>
        : null }
        { (params.value.length > 1) ? 
          <><img src={'icons/vert-line.svg'} height="20" width="20" />
          <img src={'icons/'+params.value[1].icon} height="20" width="20"></img></>
        : null }
        </>
      ),
    },
  ];
  let tableRows = [];
  if (showGrid == true) {
    kpiList.map((k, i) => {
      let o = {}
      o.Metric = k.KPI_IDENTIFIER.toString()
      o.Description = k.KPI_NAME
      o.id = i * 100
      o.Tags = k.tags
      tableRows.push(o)
    }
    )
  }

 
  console.log("list=",kpiList)

  return (  
    <div>
      <Container>
      <h2>Measure Configuration</h2>      
      <br></br>
      <Row>
      <Col md={1}>
      <Button fluid variant="outline-success" onClick={(e) => chtyp("G")}><Image fluid src='icons/plus-lg.svg' style={s.addicon}></Image></Button>
      </Col>
      <Col md={6}>
      </Col>
      <Col md={2}>
      </Col>
      <Col md={2}>
      </Col>
      </Row>
      <Row>
      <Col md={7}>
      { showGrid ? <><br></br><UiMetricGrid  selectMetric={setMetric} cols={tableCols} rows={tableRows}></UiMetricGrid></> : null}
      </Col>
      <Col md={5}>
        <br></br> 
      { showDetail ? <MetricDetail detail={sDetail[0]}></MetricDetail> : null}
      </Col>
      </Row>
      </Container>
   </div> 
  );
}

export default KpiConfigCtl;
