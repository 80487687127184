
function MapKeyBit(props) {
    
  console.log("x = ", props.k.x)
  console.log("y = ", props.k.y)
  console.log("s = ", props.k.style.plain)

  return (
    <g>
    <rect  key={props.k.key} width="20" height="20"  x={props.k.x} y={props.k.y} style={props.k.style.plain}></rect>
    <text  key={"T"+props.k.key} x={props.k.x+25} y={props.k.y + 15}>{props.k.lab}</text>
    </g>
 
  );
}


export default MapKeyBit;
