
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Popover from 'react-bootstrap/Popover';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import React, { useState,  useEffect, StrictMode } from "react";
import KpiSelect from './KpiSelect';
import KpiSelectGranularity from './KpiSelectGranulatiry';
import KpiTimeChart from './KpiTimeChart';

let url_server = process.env.REACT_APP_DCI_UTIL_URL 
if (process.env.REACT_APP_DCI_UTIL_PORT != "80") {
  url_server = url_server + ":" + process.env.REACT_APP_DCI_UTIL_PORT
}
console.log("url = ", url_server)



async function loadKpiList(smd, smr) {
// loads a warehouse plan with the designated higlighting
// smr == function that is told false when loading and true when loaded
// smd == function that is passed the result of the api lookup

  smr(false)
  
  const url = url_server + "/kpiAPI/1/kpilist"
  console.log("url 2 = ", url_server)
  let response = await fetch(url,
      {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
}
      });
  let rsp = await response.json();
  smd(rsp);
  smr(true)
};

async function loadKpiResults(cpy_cd,facl,whse, kpi, smd, smr,sg,dd) {
  // loads a warehouse plan with the designated higlighting
  // smr == function that is told false when loading and true when loaded
  // smd == function that is passed the result of the api lookup
  
    smr(false)
    
    const url = url_server + "/kpiAPI/1/kpivalues"
                +"?kpi=" + kpi +"&cpy_cd=" + cpy_cd + "&facl=" + facl + "&whse=" + whse
    
    console.log("url=",url)

    let response = await fetch(url,
        {
        method: "GET",
        mode: "cors",
        headers: {
          "Accept": "application/json",
}
        });
    let rsp = await response.json();
    smd(rsp);
    sg("Q")
    dd(KpiCalcDisplayData("Q",rsp))
    smr(true)
  };

function makeTwo(c){
  if (Number(c) <= 9) {
    return "0" + c
  }
  else {
    return c
  }
};  
  
function KpiCalcDisplayData(granularity,chartData){
  let q = [];
  let dd = { data:[], info:[]}

  let qCount = 0;
  let prevTimeUnit = "";
  let prevDimm = "$$";
  let firstRow = true;
  let outRow = {TimeUnit:"",KPI_Value:0,KPI_Comp_01: 0,KPI_Comp_02: 0}
  let mm = {min:9999999, max:0}
// YY-QQ-MM-WW-DD

  chartData.map((d)=>{
    if (prevDimm != d.KPI_DIMENSION_01) {
      console.log("new dim =",d.KPI_DIMENSION_01)
      if(prevDimm != "$$"){
        if (outRow.KPI_Comp_02 == 0) {
          outRow.KPI_Value = outRow.KPI_Comp_01  
        }
        else {
          outRow.KPI_Value = outRow.KPI_Comp_01 / outRow.KPI_Comp_02
        }
        dd.data[qCount].push(Object.assign({},outRow))
        if (outRow.KPI_Value > mm.max) {mm.max = outRow.KPI_Value}
        if (outRow.KPI_Value < mm.min) {mm.min = outRow.KPI_Value}
        dd.info[qCount]=Object.assign({},mm)
        mm.min = 9999999
        mm.max = 0 
        qCount = qCount + 1
        firstRow = true
      }
      prevDimm = d.KPI_DIMENSION_01
    }

    switch(granularity){
      case "Q":
        if (d.KPI_YEAR.toString() + makeTwo(d.KPI_QUARTER.toString()) != outRow.TimeUnit){
            if (firstRow) {
              dd.data[qCount] = []
              firstRow = false;
            }
            else {
              if (outRow.KPI_Comp_02 == 0) {
                outRow.KPI_Value = outRow.KPI_Comp_01  
              }
              else {
                outRow.KPI_Value = outRow.KPI_Comp_01 / outRow.KPI_Comp_02
              }
              dd.data[qCount].push(Object.assign({},outRow))
              if (outRow.KPI_Value > mm.max) {mm.max = outRow.KPI_Value}
              if (outRow.KPI_Value < mm.min) {mm.min = outRow.KPI_Value}
      
            }
            outRow.TimeUnit = d.KPI_YEAR.toString() + makeTwo(d.KPI_QUARTER.toString())
            
            outRow.KPI_Comp_01 = Number(d.KPI_COMP_01)
            outRow.KPI_Comp_02 = Number(d.KPI_COMP_02)
        }
        else {
          outRow.KPI_Comp_01 = outRow.KPI_Comp_01 + Number(d.KPI_COMP_01)
          outRow.KPI_Comp_02 = outRow.KPI_Comp_02 + Number(d.KPI_COMP_02)
          outRow.Dim = d.KPI_DIMENSION_01
        }  
        break;
      case "M":
        if (d.KPI_YEAR.toString() + makeTwo(d.KPI_MONTH.toString()) != outRow.TimeUnit){
            if (firstRow) {
              dd.data[qCount] = []
              firstRow = false;
            }
            else {
              if (outRow.KPI_Comp_02 == 0) {
                outRow.KPI_Value = outRow.KPI_Comp_01  
              }
              else {
                outRow.KPI_Value = outRow.KPI_Comp_01 / outRow.KPI_Comp_02
              }
              dd.data[qCount].push(Object.assign({},outRow))
              if (outRow.KPI_Value > mm.max) {mm.max = outRow.KPI_Value}
              if (outRow.KPI_Value < mm.min) {mm.min = outRow.KPI_Value}        
            }
            outRow.TimeUnit = d.KPI_YEAR.toString() + makeTwo(d.KPI_MONTH.toString())
            outRow.KPI_Comp_01 = Number(d.KPI_COMP_01)
            outRow.KPI_Comp_02 = Number(d.KPI_COMP_02)
        }
        else {
          outRow.KPI_Comp_01 = outRow.KPI_Comp_01 + Number(d.KPI_COMP_01)
          outRow.KPI_Comp_02 = outRow.KPI_Comp_02 + Number(d.KPI_COMP_02)
          outRow.Dim = d.KPI_DIMENSION_01
        }  
        break;
      case "W":
        if (d.KPI_YEAR.toString() + makeTwo(d.KPI_WEEK.toString()) != outRow.TimeUnit){
            if (firstRow) {
              dd.data[qCount] = []
              firstRow = false;
            }
            else {
              if (outRow.KPI_Comp_02 == 0) {
                outRow.KPI_Value = outRow.KPI_Comp_01  
              }
              else {
                outRow.KPI_Value = outRow.KPI_Comp_01 / outRow.KPI_Comp_02
              }
              dd.data[qCount].push(Object.assign({},outRow))
              if (outRow.KPI_Value > mm.max) {mm.max = outRow.KPI_Value}
              if (outRow.KPI_Value < mm.min) {mm.min = outRow.KPI_Value}      
            }
            outRow.TimeUnit = d.KPI_YEAR.toString() + makeTwo(d.KPI_WEEK.toString())
            outRow.KPI_Comp_01 = Number(d.KPI_COMP_01)
            outRow.KPI_Comp_02 = Number(d.KPI_COMP_02)
            
        }
        else {
          outRow.KPI_Comp_01 = outRow.KPI_Comp_01 + Number(d.KPI_COMP_01)
          outRow.KPI_Comp_02 = outRow.KPI_Comp_02 + Number(d.KPI_COMP_02)
          outRow.Dim = d.KPI_DIMENSION_01
        }  
        break;
      default:
        if (firstRow) {
            dd.data[qCount] = []
            firstRow = false;
        }
        outRow.TimeUnit = d.KPI_DATE
        outRow.KPI_Comp_01 = Number(d.KPI_COMP_01)
        outRow.KPI_Comp_02 = Number(d.KPI_COMP_02)
        outRow.KPI_Value = Number(d.KPI_VALUE)
        outRow.Dim = d.KPI_DIMENSION_01
        dd.data[qCount].push(Object.assign({},outRow))
        if (outRow.KPI_Value > mm.max) {mm.max = outRow.KPI_Value}
        if (outRow.KPI_Value < mm.min) {mm.min = outRow.KPI_Value}

      }
    } )
    if (outRow.KPI_Comp_02 == 0) {
      outRow.KPI_Value = outRow.KPI_Comp_01  
    }
    else {
      outRow.KPI_Value = outRow.KPI_Comp_01 / outRow.KPI_Comp_02
    }
    dd.data[qCount].push(Object.assign({},outRow))
    if (outRow.KPI_Value > mm.max) {mm.max = outRow.KPI_Value}
    if (outRow.KPI_Value < mm.min) {mm.min = outRow.KPI_Value}
    dd.info[qCount]=Object.assign({},mm)

    console.log("q=",dd)

    return dd;
  }

  

function KpiTimeCtl(props) {

  const [fwhse,setFwhs] = useState("210");
  const [kpi,setKpi] = useState("None");
  const [chartData,setChartData] = useState("WAIT");
  const [kpiList,setKpiList] = useState("WAIT");
  const [kpiListFlag,setKpiListFlag] = useState(false);
  const [renderChart,setRenderChart] = useState(false);
  const [firstTime, setFirstTime] = useState(true)
  const [chartType, setChartType] = useState("1")
  const [granularity, setGranularity] = useState("Q")
  const [displayData, setDisplayData] = useState("")
  const sml = (q) => { {setKpiList(q); console.log("setting KpiList",q)} }
  const srl = (q) => { {setKpiListFlag(q); console.log("setting KPIList Render Flag",q)}}
  const smc = (q) => { {setChartData(q); console.log("setting chartData")} }
  const src = (q) => { {setRenderChart(q); console.log("setting Chart Render Flag",q)}}
  const chtyp = (q) => { {setChartType(q); console.log("setting Chart Type ",q)}}
  const dd = (q) => { {setDisplayData(q); console.log('Setting Display Data =',q)}}

  // get list of KPIs 
  useEffect(() => {
    loadKpiList(sml,srl);
  },[firstTime])

  // Get KPI data for 
  useEffect(() => {
    console.log("****kpi=",kpi)
    if (kpi != "" && kpi != "None"){
      loadKpiResults('DRAKE',fwhse.substring(0,1),fwhse.substring(1,3), kpi, smc,src,setGranularity,dd); 
    }
  },[kpi,fwhse]
  )

  // Get Granularity 
  useEffect(() => {
    console.log("**granularity=",granularity)
    if (renderChart){
      dd(KpiCalcDisplayData(granularity,chartData))
      console.log("**data =",displayData)  
    }

  },[granularity]
  )


  console.log("list=",kpiList)

  return (  
    <div>
      <Container>
      <h2>Metric Over Time</h2>      
      <Row>
      <Col md={4}>
      <Card>
      { kpiListFlag ? <KpiSelect sk={setKpi} kpilist={kpiList}> </KpiSelect> : null }

      <br></br>    
      <KpiSelectGranularity sg={setGranularity} currValue={granularity}> </KpiSelectGranularity>

      </Card>

      </Col>
      <Col md={8}>
        <Card>
        <Tabs>
          <Tab eventKey="line" title="Line">
          { renderChart ? <KpiTimeChart chartType="G" chartData={displayData} granularity={granularity}/> : null }
          </Tab>
          <Tab eventKey="bar" title="Bar">
          { renderChart ? <KpiTimeChart chartType="B" chartData={displayData} granularity={granularity}/> : null }
          </Tab>
          <Tab eventKey="table" title="Table">
          { renderChart ? <KpiTimeChart chartType="T" chartData={displayData} granularity={granularity}/> : null }
          </Tab>
          <Tab eventKey="pivot" title="Pivot">
          { renderChart ? <KpiTimeChart chartType="P" chartData={displayData} granularity={granularity}/> : null }
          </Tab>
          <Tab eventKey="info" title="Info">
          { renderChart ? <KpiTimeChart chartType="P" chartData={displayData} granularity={granularity}/> : null }
          </Tab>

        </Tabs>

      </Card>
      </Col>
      </Row>
      </Container>
   </div> 
  );
}

export default KpiTimeCtl;
