import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

function handleLogin(instance, accounts) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });

};

/**
 * Renders a button which, when selected, will open a popup for login
 */
function  SignInButton(props) {
    const { instance, accounts } = useMsal();


    return (
        <>
        <Button variant="light"  onClick={() => handleLogin(instance,accounts)}>
            <Image src="ms-signin.png" ></Image>
        </Button>
        </>
    );
};

export default SignInButton;