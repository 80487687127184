

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Form } from 'react-bootstrap';

import React, { useState,  useEffect, StrictMode } from "react";

let url_server = process.env.REACT_APP_DCI_UTIL_URL 
if (process.env.REACT_APP_DCI_UTIL_PORT != "80") {
  url_server = url_server + ":" + process.env.REACT_APP_DCI_UTIL_PORT
}


async function loadPlan(fwhs,filter,smd, smr) {
// loads a warehouse plan with the designated higlighting
  smr(false)
  let cpy = ""
  if (fwhs == "210") {
    cpy = "DRAKE"
  } 
  else {
    cpy = "MRPCO"
  }
  console.log("FW",fwhs,fwhs.substring(0,1),fwhs.substring(1,2))
  const url = url_server + "/whsePlan/1/planData"
               + "?cpy=" + cpy + "&facl=" + fwhs.substring(0,1)
               + "&whse=" + fwhs.substring(1) + "&query=" + filter
  console.log("url=",url) ;

  let response = await fetch(url,
      {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
}
      });
  let rsp = await response.json();
  smd(rsp);
  smr(true)
};



function GridCtl(props) {

  const [fwhse,setFwhs] = useState("110");
  const [filter,setFilter] = useState("None");
  const [mapData,setMapData] = useState("WAIT");
  const [renderPlan,setRenderPlan] = useState(false);

  const sm = (q) => { {setMapData(q); console.log("setting MapData")} }
  const sr = (q) => { {setRenderPlan(q); console.log("setting Render Flag",q)} }

  const currApp = ""


  useEffect(() => {
    console.log("Fwhs=",fwhse)
    console.log("Filter=",filter)
    loadPlan(fwhse,filter,sm,sr);
  },[fwhse,filter]
  )
  
  useEffect(() => {
    if (mapData != "WAIT" ) {
      setRenderPlan(true)
      console.log("1 = ", mapData.slotdetails[1].X_CORD,mapData.slotdetails[1].Y_CORD,mapData.slotdetails[1])
      console.log("It did! It changed") }
  },[mapData]
  )


  return (
    <div>
      <Form>
      <Container>
        <Row>
          <Col md={5}>
          <h3>Warehouse Plan</h3>
          </Col>
          <Col md={2}></Col>
          <Col md={3}>

          </Col>
          <Col md={2}>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row><br></br><br></br>
        </Row>
        <Row>
        <Col md={10}>

        </Col>
        <Col md={2}></Col>
        </Row>
      </Container>
      </Form>
    <br></br>
   </div> 
  );
}

export default GridCtl;
