

import {useState, useEffect} from 'react';
import MapKeyBit from './MapKeyBit';



function MapKey(props) {
  
  let kArray = []
  let j = 0 
  let Kstart = 40
  let Kinc = 70
  let Cy = 50
  props.colArray.map((c) =>
       {   const s = {plain: { fill: c } }
           const Cx = Kstart + j * Kinc
           const key = j.toString()
           const t = (j+1)*10
           const tt = t.toString() + "%"
           const g = {
             color: c,
             style: s,
             x: Cx,
             y: Cy,
             lab: tt 
           }
           kArray.push(g)
           j = j +1 
       }
  )

  console.log("colArray = ", props.colArray);
  console.log("kArray = ", kArray);


 
  return (
    <svg key="mapkey" width="700" height="100" viewBox="0 0 800 100">
       <rect  width="12" height="12"  x="40" y="50" />
       {kArray.map((k) =>   <MapKeyBit k={k} /> )}
    </svg>
   
 
  );
}


export default MapKey;
