
import React, {useState, useEffect, MouseEvent } from "react"

import { DataGrid, gridColumnLookupSelector, GridToolbar } from '@mui/x-data-grid';



 export function  UiCrudGrid( props) {

  let [sRows, setRows] = useState(props.rows);
  let [sFunc, setFunc] = useState(props.func);
  
  useEffect(() => {
      setRows(props.rows);
  },[props.rows]);

  let tableHeight = 6000; 

  if (props.height == undefined) {
     tableHeight = 6000
   }
   else {
     tableHeight = props.height
   }

  console.log("rows = ", sRows)
  console.log("cols = ", props.cols)

   return ( 
      <div style={{ height: 600, width: '100%' }}>
          <DataGrid  
            components={{ Toolbar: GridToolbar }} 
            columns={props.cols} 
            disableMultipleSelection={true}
            rows={sRows} 
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = sRows.filter((row) =>
              selectedIDs.has(row.id)
              )
              selectedRowData.map((row) =>
                props.selectMetric(row.Metric))
              }}
      />
      </div>
    );
};

