

import {useState} from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import {VictoryBar, VictoryTooltip, VictoryLine ,VictoryAxis, VictoryGroup, VictoryChart}  from 'victory';
import {UiGrid} from './UiGrid';


function KpiTimeChart(props) {

  let showLine = false;
  let showBar = false;
  let showTable = false;
  let showPivot = false;

  const specialSizes = {
    Q: {labelFont:5, lineStroke:2, labelFrequency:1, axisLabelFreq:1, axisLabelFont:8,barwidth: 8 },
    M: {labelFont:5, lineStroke:2, labelFrequency:1, axisLabelFreq:1, axisLabelFont:4,barwidth: 4},
    W: {labelFont:5, lineStroke:2, labelFrequency:6, axisLabelFreq:5, axisLabelFont:4,barwidth: 1},
    D: {labelFont:2, lineStroke:2, labelFrequency:20, axisLabelFreq:6, axisLabelFont:5,barwidth: 1}
 }
 
  if (props.chartType == "G") {
    showLine = true; 
  }
  if (props.chartType == "B") {
    showBar = true; 
  }
  if (props.chartType == "T") {
    showTable = true; 
  }
  if (props.chartType == "P") {
    showPivot = true; 
  }


  // Calculate Top Axis
  const displayData = props.chartData.data[0];
  const displayMin =  props.chartData.info[0].min
  let displayMax = 0  
  props.chartData.info.map((i)=> {
    if(i.max > displayMax) {
      displayMax = i.max
    }
  })
  displayMax = displayMax * 1.1
  let sizes ={};
  switch(props.granularity) {
    case "Q" :  {sizes=specialSizes.Q;break;}
    case "M" :  {sizes=specialSizes.M;break;}
    case "W" :  {sizes=specialSizes.W;break;}
    default :  {sizes=specialSizes.D}
  }

  // X Axis Labels
  let labs = [];  
  let labf = [];
  console.log("dsp=",displayData)
  if (props.granularity == "Q" || props.granularity == "W"){
    displayData.map((d,i) => {
      labs.push(d.TimeUnit)
      if (i % sizes.axisLabelFreq == 0 ){
        labf.push(d.TimeUnit.substr(2,2) + "-" + d.TimeUnit.substr(4,2) )
      }
      else {
        labf.push("")
      }
  } ) }
    
  console.log("dims=",props.chartData.data.length)
  console.log("chartData=",props.chartData.data)

  let groupedBars = false
  if (props.chartData.data.length > 1) {
     groupedBars = true

  }
  

  const xAxisQW = 
  <VictoryAxis 
    standalone={false}
    tickValues={labs}
    tickFormat={labf}
    domain={{y:[0,displayMax]}}
    domainPadding={{x:20, y:50}}
    style={{
    axis: {stroke: "#756f6a"},
    axisLabel: {fontSize: 20, padding: 30},
    ticks: {stroke: "grey", size: 5},
    tickLabels: {fontSize: sizes.axisLabelFont, padding: 5}}}>
  </VictoryAxis>

const yAxisQW = 
  <VictoryAxis 
    dependentAxis={true}
    standalone={false}
    domain={{y:[0,displayMax]}}
    domainPadding={{x:0, y:50}}
    style={{
    axis: {stroke: "#756f6a"},
    axisLabel: {fontSize: 8, padding: 30},
    ticks: {stroke: "grey", size: 3},
    tickLabels: {fontSize: 6, padding: 5}}}>
  </VictoryAxis>


let lab=[];
props.chartData.data.map((d)=> {
  let l =[];
  d.map((dd,i)=> {
    if (i % sizes.labelFrequency == 0) {
      l.push(Math.round(dd.KPI_Value))
    }
    else {
      l.push("")
    }
    
  })
  lab.push(l)
}
)

let barChart = <></>  
if (groupedBars) {
     barChart = 
    <VictoryGroup 
      standalone={false}
      domain={{y:[0,displayMax]}}
      key="grp001"
      domainPadding={{x:20}}
      offset={sizes.barwidth}
      style={{ data:{width: sizes.barwidth}}}
      colorScale={["#4080bf", "#ac3973", "#e67300", "#884dff"]}
     >
       {
         props.chartData.data.map((d,i)=> 
            <VictoryBar key={i} standalone={false}  data={d} y="KPI_Value"  x="TimeUnit" labels={lab[i]} 
            labelComponent={<VictoryTooltip />}
            domain={{y:[0,displayMax]}} style={{labels: {fontSize: sizes.labelFont,  fill: "black"}}}  ></VictoryBar>
         )
       }
    </VictoryGroup>
  }
  else {
     barChart =
    <VictoryBar 
       data={displayData}
       style={{data: {fill: "#4080bf"},labels: {fontSize: sizes.labelFont,  fill: "black"}} } 
       standalone={false} 
       y="KPI_Value" 
       x="TimeUnit"
       labels={lab[0]}
                />
  }

  const tableCols = [
    { field: 'dim', headerName: 'Dimension', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'kpivalue', headerName: 'Metric Value', width: 150 },
  ];

  let pivotCols = [
    { field: 'date', headerName: 'Date', width: 150 }
  ]
  

  // sets data for table
  let tableRows =[];    // rows for simple list table 
  let wtableRows =[];   // work rows to build pivot

  props.chartData.data.map((d,i)=> {
     d.map((k,j) =>  {
      let r={}
      let w={}
      r.id = i *1000 + j
      r.dim = k.Dim
      r.date = k.TimeUnit
      r.kpivalue = Math.round(k.KPI_Value)
      tableRows.push(r);
      w.Dim =  k.Dim 
      w.KPI_Value=Math.round(k.KPI_Value)
      w.date = k.TimeUnit
      wtableRows.push(w);
     }  
     )
  } );

  wtableRows.sort(function (a,b) { 
    if (b.date > a.date) {
      return 1
    }
    else {
      if (b.date < a.date) {
        return -1 
      }
      else {
        if (b.dim > a.dim) {
          return 1 
        }  
        else {
          return -1
        }
        } 
      }
    }
  )
  
  let prevdate = ''
  let ptableRows = []
  let firstGo = true
  let p = {}
  let dimList =[]

  console.log("sss=",wtableRows);


  wtableRows.map((w) => {
    if (w.date != prevdate) {
      if (firstGo === true){
        firstGo = false
      }
      else{
        ptableRows.push(Object.assign({},p))
      }
      prevdate = w.date
    }

   // Add it to pivot list  
    p.date = w.date
    p.id = w.date
    p[w.Dim] = w.KPI_Value

   // add it to a dim list 
    let inList = false
    dimList.map((d) => {
      if (d === w.Dim) {
        inList = true
      }
    }
    )
    if (inList === false) {
      dimList.push(w.Dim)
    }

  })
  ptableRows.push(Object.assign({},p))

  console.log("ppp=",ptableRows)
  console.log("lll=",dimList)

  dimList.map((d) => 
  {
    let w={}
    w.field = d;
    w.headerName = d;
    w.width = 100
    pivotCols.push(w)
  }
  )



  let lineChart = <></>  
  if (groupedBars) {
       lineChart = 
      <VictoryGroup 
        standalone={false}
        domain={{y:[0,displayMax]}}
        domainPadding={{x:20}}
        key="grp001"
        offset={0}
        style={{ data:{width: 1}}}
        colorScale={["#4080bf", "#ac3973", "#e67300", "#884dff"]}
       >
         {
           props.chartData.data.map((d,i)=> 
              <VictoryLine key={i} standalone={false}  data={d} y="KPI_Value"  x="TimeUnit"  labels={lab[i]} labelComponent={<VictoryTooltip />}
              domain={{y:[0,displayMax]}} style={{labels: {fontSize: sizes.labelFont,  fill: "black"},data: {strokeWidth: 1 }}}  ></VictoryLine>
           )
         }
      </VictoryGroup>
    }
    else {
      lineChart =
      <VictoryLine data={displayData} standalone={false} y="KPI_Value"  x="TimeUnit" domain={{y:[0,displayMax]}}
        style={{data: { stroke: "#4080bf", strokeWidth: 1 },parent: { border: "1px solid #ccc"}}} />
    }

  return (
    <div>
      { showLine ?  <svg  width="100%" viewBox="0 0 450 400" >  {xAxisQW} {yAxisQW} {lineChart} </svg>: null }
      { showBar ?   <svg  width="100%" viewBox="0 0 450 400">   {xAxisQW} {yAxisQW} {barChart}  </svg> : null }
      { showTable ? <><br></br><UiGrid  cols={tableCols} rows={tableRows}></UiGrid></> : null}
      { showPivot ? <><br></br><UiGrid  cols={pivotCols} rows={ptableRows}></UiGrid></> : null}

    </div> 
  );
  }


export default KpiTimeChart;
