

import {useState, useEffect} from 'react';
import Select from 'react-select';




function KpiSelectGranularity(props) {

  const [granularity,setGranularity] = useState("D")
  const tt = (m) =>
  {
    setGranularity(m);
    console.log("m=",m) 
    props.sg(m.value)
  } 
  let currValue = {value:"D", label:"Daily"}
  switch(props.currValue){
    case "Q": currValue = {value:"Q", label:"Quarterly"}; break;
    case "M": currValue = {value:"M", label:"Monthly"}; break;
    case "W": currValue = {value:"W", label:"Weekly"}; break;
  }
  console.log("gran=",granularity)

  let z=[
    {value:"D", label:"Daily"},
    {value:"W", label:"Weekly"},
    {value:"M", label:"Monthly"},
    {value:"Q", label:"Quarterly"}
  ]
  ;
  
  return (
    <Select options={z}  onChange={tt} />
  );
}


export default KpiSelectGranularity;
