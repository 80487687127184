

import {useState, useEffect} from 'react';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';

function OperatorSelect(props){ 
  const opts = 
    [
      {value:"+",label:"+"},
      {value:"-",label:"-"},
      {value:"*",label:"*"},
    ]

  return (
    <Select options = {opts}></Select>
  )  
}

function MeasureSelect(props){ 
  const opts = 
    [
      {value:"1",label:"Pick Hours"},
      {value:"2",label:"Pick Units"},
      {value:"3",label:"Unaccounted Hours"},
    ]

  return (
    <Select options = {opts}
      formatOptionLabel = {(opt) => (opt.value + " - " + opt.label)}>
    </Select>
  )  
}

function FormulaLine(props){ 
  
  return (
    <>
    <OperatorSelect></OperatorSelect>
    <MeasureSelect></MeasureSelect>
    </>
  )  
}


export function KpiFormulaEdit(props) {
  
  let kpiFormula = 
  { "name": "Name",
  "id": 0,
  "numerator": 
    {"firstMeasure": "1",
     "detail": 
      [  {"operator": "+", "measure": "1" },
            {"operator": "+", "measure": "1" },
    ]
    },
  "denominator":
    {"firstMeasure": "fldA",
        "detail": 
  [     {"operator": "+", "field": "fldB" },
            {"operator": "+", "field": "fldC" },
    ]
    }
  }
  
  return (
    <>

    <Table>
      <tbody>
        <tr>
          <td style={{width:"20%"}}></td>
          <td style={{width:"80%"}}><MeasureSelect></MeasureSelect></td>
        </tr>
        {kpiFormula.numerator.detail.map((o)=>
            <tr>
                  <td style={{width:"20%"}}><OperatorSelect value={o.operator}></OperatorSelect></td>
                  <td style={{width:"80%"}}><MeasureSelect value={o.measure}></MeasureSelect></td>
            </tr>
        )}
      </tbody>
    </Table>
    </>
  );
}


