
import MapPosition from './MapPosition';
import MapKey from './MapKey';
import {useState} from 'react';



function cleanSlotList(slotlist, whsedetails, Cm,Cmm) {

  const filterFactor = 20
  const cleanOption = '0'



  if (cleanOption == '0') {
    Cmm.XXmax = Cm.Xmax 
    Cmm.XXmin = Cm.Xmin
    Cmm.YYmax = Cm.Ymax
    Cmm.YYmin = Cm.Ymin
    return slotlist
  }
  else {
    Cm.Xmax = parseFloat(whsedetails.AVG_X_CORD) + filterFactor * parseFloat(whsedetails.STDDEV_X_CORD) 
    Cm.Ymax = parseFloat(whsedetails.AVG_Y_CORD) + filterFactor * parseFloat(whsedetails.STDDEV_Y_CORD) 
    Cm.Xmin = parseFloat(whsedetails.AVG_X_CORD) - filterFactor * parseFloat(whsedetails.STDDEV_X_CORD) 
    Cm.Ymin = parseFloat(whsedetails.AVG_Y_CORD) - filterFactor * parseFloat(whsedetails.STDDEV_Y_CORD) 


    let slotlist2 = []
    slotlist.map((s) => {
      if ( s.X_CORD < Cm.Xmax 
        && s.X_CORD > Cm.Xmin 
        && s.Y_CORD < Cm.Ymax 
        && s.Y_CORD > Cm.Ymin ) {

            slotlist2.push(s)
            if (s.X_CORD < Cmm.XXmin) { Cmm.XXmin = s.X_CORD}
            if (s.X_CORD > Cmm.XXmax) { Cmm.XXmax = s.X_CORD}
            if (s.Y_CORD < Cmm.YYmin) { Cmm.YYmin = s.Y_CORD}
            if (s.Y_CORD > Cmm.YYmax) { Cmm.YYmax = s.Y_CORD}
        }
     })
    return slotlist2
   }
  };



function WhsePlan(props) {


  let showKey = false;
  if (props.highlight != "None") {
    showKey = true
  }

  const Xbuffer = 0.1
  const Ybuffer = 0.1
  const Xwidth =1000
  const Yheight = 600
  const [sR,setR] = useState(0);
  const reRender = () => setR(sR+1);



  const colArray = [
    '#000099',
    '#0000ff',
    '#4d4dff',
    '#8080ff',
    '#ccccff',
    '#ffcccc',
    '#ff9999',
    '#ff6666',
    '#ff3333',
    '#e60000'     
  ]

  let Xorigin = 0 
  let Yorigin = 0
  let Xend = 0
  let Yend = 0

  let Cmm = {
    XXmin:999999999,
    XXmax:0,
    YYmin:999999999, 
    YYmax:0
  }   

  let XMap = ""
  let YMap = ""

  let Cm = 
    {
      Xmax:0,
      Xmin:0,
      Ymax:0,
      Ymin:0
    }

  Cm.Xmax = parseFloat(props.whsedetails.MAX_X_CORD)
  Cm.Xmin = parseFloat(props.whsedetails.MIN_X_CORD)
  Cm.Ymax = parseFloat(props.whsedetails.MAX_Y_CORD)
  Cm.Ymin = parseFloat(props.whsedetails.MIN_Y_CORD)

  console.log("min-max=",props.whsedetails)
  

  let slotlist2 = props.slotlist

  console.log("clean=",Cm)
  console.log("dirty=",Cmm)

  // Calculate the Origin      
      Xorigin = Cm.Xmin - ( Xbuffer) * (Cm.Xmax - Cm.Xmin) 
      Xend = Cm.Xmax + ( Xbuffer) * (Cm.Xmax - Cm.Xmin)
      Yorigin = Cm.Ymin - ( Ybuffer) * (Cm.Ymax - Cm.Ymin) 
      Yend = Cm.Ymax + ( Ybuffer) * (Cm.Ymax - Cm.Ymin)
       

  // Calculate the Scale Conversion
      const Xconv = Xwidth / (Xend - Xorigin)  
      const Yconv = Yheight / (Yend - Yorigin) 
      console.log("XX",Xconv,Xend,Xorigin)
      console.log("YY",Yconv,Yend,Yorigin)

      const Xmapper = (n) => { return ( ( n - Xorigin) * Xconv ) };
      const Ymapper = (n) => {return (Yheight - ( Yconv * (n - Yorigin)))};



  return (
    <div>
      { showKey? <MapKey colArray={colArray} /> : null }
      <svg  key="mainmap" width="1300" height="600"  viewBox="0 0 1200 600">

       { slotlist2.map((s) => 

          <MapPosition aisle={s.AISLE} bay={s.BAY} x={Xmapper(s.X_CORD)} y={Ymapper(s.Y_CORD)} color={colArray} 
          defaultColor='#33395D' data={s.data} reRender={reRender}></MapPosition>

          ) 
    }
    </svg>
   </div> 
  );
}


export default WhsePlan;
