

import './App.css';
import Topbar from './Topbar'
import Tag from './tag'
import MainApp from './MainApp'

import React, { useState, createContext, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import  SignInButton  from "./SignInButton";
import { callMsGraph } from "./graph";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

let apps =
  [ 
  { App:"CostToServe",
   Title: "Cost to Serve",
   MenuTitle: "Cost to Serve",
   Menus: [
     {MenuName: "Models",
      MenuOptions:[
        {name:"Models", function:"CTSModel"},
        {name:"Model Results", function:"CTSModelResults"}
      ]
     },
     {MenuName: "Set Up",
      MenuOptions:[
       {name:"CTS Config", function:"MainCrud"},
     ]
    },
   ]
  },

  { App:"ControlTower",
   Title: "Control Tower",
   MenuTitle: "Control Tower",
   Menus: [
     {MenuName: "DC",
      MenuOptions:[
        {name:"Inbound", function:"CTDCIn"},
        {name:"Outbound", function:"CTDCOut"},
        {name:"Utilization", function:"CTDCUtil"}
      ]
     },
     {MenuName: "Set Up",
      MenuOptions:[
       {name:"Config", function:"CTConfig"},
     ]
    },
   ]
  },

  { App:"Metrics",
  Title: "Metrics and KPIs",
  MenuTitle: "Metrics",
  Menus: [
    {MenuName: "Views",
     MenuOptions:[
       {name:"Measures Over Time", function:"MetricTime"},
       {name:"Daily Review", function:"MetricDaily"}
     ]
    },
    {MenuName: "Configuration",
     MenuOptions:[
      {name:"Measure Set Up", function:"MetricSetUp"}
    ]
   },
  ]
 },

  { App:"Optimization",
  Title: "Optimization",
  MenuTitle: "Optimization",
  Menus: [
    {MenuName: "Warehouse Set Up",
     MenuOptions:[
       {name:"Warehouse Areas", function:"OptArea"},
       {name:"Locations", function:"OptLoc"},
       {name:"Pick Assignments", function:"OptiPicks"},
       {name:"Slotting Preferences", function:"OptiPrefs"},
       {name:"Standards", function:"OptiStandards"}
     ]
    },
    {MenuName: "Items",
     MenuOptions:[
       {name:"Item Groups", function:"OptiItemGrp"},
       {name:"Items", function:"OptiItem"},
       {name:"Demand History", function:"OptiDemand"}
     ]
    },
    {MenuName: "Optimize",
     MenuOptions:[
       {name:"Current", function:"OptiCurrent"},
       {name:"Model", function:"OptiModel"},
       {name:"Standard Simulator", function:"OptiStdSim"},
     ]
    }
  ]
 },
 { App:"Reporting",
   Title: "Reporting",
   MenuTitle: "Reporting",
   Menus: [
     {MenuName: "Dashboards",
      MenuOptions:[
        {name:"View", function:"dashView"},
        {name:"Configure", function:"dashConfig"}
      ]
     },
     {MenuName: "Plans",
     MenuOptions:[
       {name:"Warehouse View", function:"whsePlan"},
       {name:"Section Views", function:"sectPlan"}
     ]
    },

     {MenuName: "Extracts",
     MenuOptions:[
       {name:"Extract Data", function:"extRun"},
       {name:"Schedule Extracts", function:"extSchd"}
     ]
    },
    {MenuName: "Notifications",
     MenuOptions:[
       {name:"Recipients", function:"notRecipient"},
       {name:"Notifications", function:"notSetUp"}
     ]
    },

   ]
  },
 { App:"Configuration",
 Title: "Configuration",
 MenuTitle: "Configuration",
 Menus: [
   {MenuName: "Reporting",
    MenuOptions:[
      {name:"Extracts", function:"CfgExtract"}
    ]
   },
   {MenuName: "Integration",
    MenuOptions:[
      {name:"Integration Formats", function:"CfgIntegration"},
      {name:"Entity Mapping", function:"CfgDataMapping"},

    ]
   }
 ]
}

]



function App() {

  const isAuthenticated = useIsAuthenticated();

  const [app,setApp] = useState("Metrics");
  const [fun,setFun] = useState("");

  const [cpy,setCpy] = useState("")
  const [fwh,setFwh] = useState("")


  const [secProfile,sSecProfile] = useState({   
    validUser: true,
    authUser: "",
    authUserName:"",
    allowedCompanies:[],
    allowedApps:[],
    authMessage:""
  })
  const setSecProfile = (q) => 
  {
    console.log("setting Profile");
    sSecProfile(q)
  }

  const sa 
  = (q) => { {setApp(q); setFun(""); console.log("setting App to - " + q)} }
  const sf = (q) => { {setFun(q); console.log("setting Fun to - " + q)} }


  const sCpy = (q) => { {setCpy(q); setFun(""); console.log("setting Cpy to - " + q)} }
  const sFwh = (q) => { {setFwh(q); setFun(""); console.log("setting Fwh to - " + q)} }


  let inApp = false
  let inFun = false

  console.log("app = " + app)
  console.log("fun = " + fun)
  if (app == "Home") {
    inApp = false
  }
  else {
    inApp = true
  }
  if (fun == "") {
    inFun = false
  }
  else {
    inFun = true
  }
  
  let appDetails = {}

  apps.map((a) => {
      if (a.App == app) {
        appDetails = a
      }
  }) 

  return (

    <>
    <AuthenticatedTemplate>
       {secProfile.validUser ? 
        <Container fluid >
        <Row>
          <Col>
            <Topbar apps={apps}  setApp={sa}  appDetails={appDetails} sf={sf} setCpy={sCpy} setFwh={sFwh} secProfile={secProfile} />
          </Col>
        </Row>
      <Row> <Col className="bg-svc-side"> <br></br></Col> </Row>
      <Row>
        <Col >
        {inFun ? <MainApp fun={fun} apps={apps} fwhs={fwh} cpy={cpy} ></MainApp> : null }
        </Col>
      </Row>
      </Container>
      :
      null }
    </AuthenticatedTemplate>

    <UnauthenticatedTemplate>
        <>
      <Row>
        <Col className="bg-wcs-welcome vh-30" align="right" lg="1">
        <Image src="WCS.png"></Image>
        </Col>
        <Col className="bg-wcs-welcome vh-30" lg="11">
            <h2>CSnx-DCI</h2>    
            <br></br>
        </Col>
      </Row>
      <Row> <Col className="bg-svc-side"> <br></br></Col> 
      </Row>
      <Row>
        <Col lg="1" className="bg-svc-side vh-100">
        </Col>
        <Col  lg="10">
            <br></br>
            <h3>Welcome to CSnx-DCI!  Please Sign in to continue.</h3>
            <br></br>
            <SignInButton setSecProfile={setSecProfile}/>    
        </Col>
        <Col lg="1" className="bg-svc-side vh-100">
        </Col>
      </Row>
        </>
    </UnauthenticatedTemplate>
    </>

  );
}


export default App;

