

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import {useState, useRef, useEffect } from 'react';
import styles from './wcs.css';
import Select from 'react-select';
import { useIsAuthenticated } from "@azure/msal-react";
import  SignInButton  from "./SignInButton";
import  SignOutButton  from "./SignOutButton";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";


let url_server = process.env.REACT_APP_DCI_UTIL_URL 

if (process.env.REACT_APP_DCI_UTIL_PORT != "80") {
  url_server = url_server + ":" + process.env.REACT_APP_DCI_UTIL_PORT
}

async function getAuth(acct,ss) {

  const url = url_server + "/authAPI/1/s?" + "SignIn=" + acct.username + "&SignInDesc=" + acct.name.replace(/\s+/g, '');

  console.log("Auth URL = ", url)
  let response = await fetch(url,
      {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
      }
      });
  let rsp =  await response.json();
  ss(rsp)

};

async function getSiteList(Cpy,setSiteList,setSite) {

  const url = url_server + "/authAPI/1/siteList?" + "CPY_CD=" + Cpy;

  let response = await fetch(url,
      {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
      }
      });
  let rsp =  await response.json();
  console.log("Site List rsp = ", rsp)
  setSiteList(rsp)
  setSite(rsp[0])
};


function SelectCompany(props) {
  const selStyle =
  {
    option: (provided,state) => ({
      ... provided,
      color:'black',
      backgroundColor: 'light-grey'  
    }),
    control: (provided) => ({
      ... provided
    })
  };

  const sc = (m) =>
  {
    props.setCompany(m.value);
    console.log("m=",m) 
  } 

  let cl = []
  let currOption = {}
  props.companyList.map((c) => {
    let z={}
    z["value"] = c
    z["label"] = c
    cl.push(z)
    if (z["value"]==props.currentCompany) {
      currOption = z
    }
  } );
  return (
    <Select 
    value={currOption}
    theme={(theme) => ({
      ...theme,
      colors:{
      ... theme.colors,
          primary:  '#000033',
      },  
    })} 
    options={cl}  onChange={sc} />
  );
}

function SelectSite(props) {
  const sc = (m) =>
  {
    props.setSite(m.value);
    console.log("site=",m) 
  } 
  const selStyle =
  {
    option: (provided,state) => ({
      ... provided,
      color:'black',
      backgroundColor: 'light-grey'  
    }),
    control: (provided) => ({
      ... provided
    })
  };

  console.log("sitelist = ", props.siteList)
  let cl = []
  let currOption = {}
  props.siteList.map((c) => {
    let z={}
    z["value"] = c
    z["label"] = c
    cl.push(z)
    if (z["value"] == props.currentSite) {
        currOption = z
    }
  } );
  return (
    <Select
      value={currOption}
      options={cl}  
      styles={selStyle}
      onChange={sc} />
  );
}


function TopDrop(props) {   
  console.log("CCC MenuOptions= ",props.o.MenuOptions)
  return ( 
  props.o.MenuOptions.map((u, idx) => 
    <div key={idx}>
    <Dropdown.Item key={u.function} onClick={(e)=> props.sf(u.function)}>
      {u.name}
    </Dropdown.Item>
    </div>
  )
  )};


function TopLine(props) {
   console.log()
   return ( 
      <svg height="35px" viewBox="7 8 40 45">
          <line x1="20" y1="10" x2="20" y2="85" stroke="grey" strokeWidth={1}/>
      </svg>
   ) };  




function Topbar(props) {
// Get signed in user details 
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

// Get DCI Authority
  const acct = accounts[0]
  const [secProfile,setSecProfile] = useState({   
    validUser: true,
    authUser: "",
    authUserName:"",
    authClient:"",
    authClientName:"",
    allowedCompanies:[],
    allowedApps:[],
    authMessage:""
  })

  const sSecProfile = (s) => {
    console.log("Sec Prof =", s);
    setCompany(s.allowedCompanies[0])
    setSecProfile(s)
  }

  useEffect(() => {
    console.log("account = ", accounts[0]);
    getAuth(acct,sSecProfile)
  },[]);

// Manage company and site
  const [company,setCompany] = useState("")

  const [siteList,setSiteList] = useState(["",""])

  useEffect(() => {
    console.log("account = ", accounts[0]);
    props.setCpy(company)
    getSiteList(company,setSiteList, setSite)
  },[company]);

  const [site,setSite] = useState("")
  useEffect(() => {
    props.setFwh(site)

  },[site]);


  const s= {topicon: {width:"28px", height:"28px"},
            toplogo: {width:"30px", height:"30px"},
            applogo: {width:"25px", height:"25px"},
            iconbutton: {border:"none", backgroundColor:"#33395D",
              "&:active": {border:"none", backgroundColor:"white"}},
              appbutton: {border:"none", backgroundColor:"white",
              "&:hover": {border:"none", backgroundColor:"grey"}},
              appicon:{     height: "30px", width: "30px"},
            dpbut: {
              border:"none",
              borderstyle:"none",
              outline: "none",
              boxshadow: "none",
              backgroundColor:"#33395D",
              color:"#33395D",
              borderColor:"#33395D"
            },
            appHeader: {color:"black",fontSize:"22px",paddingLeft:"15px",paddingTop:"8px",textAlign:"right"},
            appButton: {color:"black",fontSize:"18px",paddingLeft:"12px",paddingTop:"5px",textAlign:"left"}  
          } ;

  const sAppMenu = 
  {a: {
    border:"none",
    borderstyle:"none",
    outline: "none",
    boxshadow: "none",
    backgroundColor:"white",
    borderColor:"white"
  }
}  

  const dpbut =  
  {
    border:"none",
    borderstyle:"none",
    outline: "none",
    boxshadow: "none",
    color:"#33395D",
    borderColor:"#33395D"
  } ;       

  const [showAppMenu,setShowAppMenu] = useState(false);
  const [target,setTarget] = useState(null);

  const targetAppMenu = useRef(null);

  const jumpFun = (k) => {
    props.sf(k);
  }

  const appSet = (a) => {
    setShowAppMenu(false); 
    props.setApp(a);
   }

   const appClick = (e) => {
    setShowAppMenu(!showAppMenu);
    setTarget(e.target)
   }

  const helpMenu =
  <Popover id="popover-help">
  <Popover.Header as="h3">Help Menu</Popover.Header>
  <Popover.Body>
    This is going to be fascinating!
  </Popover.Body>
  </Popover>

  const notifyMenu =
    <Popover id="popover-notify">
    <Popover.Header as="h3">Notifications</Popover.Header>
    <Popover.Body>
        You do not have any notifications
    </Popover.Body>
    </Popover>


  console.log("BBB Menus=",props.appDetails.Menus )

  
  const os = 
    props.appDetails.Menus.map((o,idx) => 

      <DropdownButton onSelect={jumpFun} id={o.MenuName} key={o.MenuName} title={o.MenuName.toUpperCase()} style={dpbut}>

       {o.MenuOptions.map((u, idx) => 

          <Dropdown.Item key={u.name} eventKey={u.function}>
            {u.name}
          </Dropdown.Item>)
        }
         </DropdownButton>  
    )


  return (
    <Navbar bg="svc" variant="dark" expand="lg">
    <Container fluid>
      
      <Dropdown>
      <Dropdown.Toggle className="topbarIconButton" variant="dark" id="dropdown-app">
      <Image  src='icons/grid-3x3-gap-fill.svg' style={s.topicon}></Image>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header>
          <h4>CSnx DC Information</h4>
        </Dropdown.Header>
        <Dropdown.Item >
           <Button variant="light" size="lg" className="appbarIconButton" onClick={(e) => {appSet("Reporting") }}>
              <Image fluid src='icons/map.svg' style={s.appicon}></Image>
              <span style={s.appButton}>Reporting</span></Button>
        </Dropdown.Item>
        <Dropdown.Item >
           <Button variant="light" size="lg" className="appbarIconButton" onClick={(e) => {appSet("ControlTower") }}>
              <Image fluid src='icons/control-tower.svg' style={s.appicon}></Image>
              <span style={s.appButton}>Control Tower</span></Button>
        </Dropdown.Item>
        <Dropdown.Item >
           <Button variant="light" size="lg"  fluid className="appbarIconButton" onClick={(e) => {appSet("Metrics") }}>
               <Image  src='icons/speedometer.svg' style={s.appicon}></Image>
               <span style={s.appButton}>Metrics and KPIs</span>
              </Button>
        </Dropdown.Item>
        <Dropdown.Item >
          <Button variant="light" size="lg" className="appbarIconButton" onClick={(e) => {appSet("CostToServe") }}>
              <Image fluid src='icons/cost-to-serve.svg' style={s.appicon}></Image>
              <span style={s.appButton}>Cost to Serve</span></Button>
        </Dropdown.Item>

        <Dropdown.Item >
            <Button variant="light" size="lg"  fluid className="appbarIconButton" onClick={(e) => {appSet("Optimization") }}>
                  <Image  src='icons/Opti4.png' style={s.appicon}></Image>
                  <span style={s.appButton}>Optimization</span>
            </Button>
        </Dropdown.Item>
        <Dropdown.Item >
            <Button variant="light" size="lg"  fluid className="appbarIconButton" onClick={(e) => {appSet("Configuration") }}>
              <Image  src='icons/gear-fill-black.svg' style={s.appicon}></Image>
              <span style={s.appButton}>Configuration</span>
            </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <TopLine />
      <Navbar.Brand >
        <Image  src="WCS.png" alt="" style={s.toplogo} />
      </Navbar.Brand>
      <Navbar.Text><div className="svcAppName">{props.appDetails.Title}</div></Navbar.Text>
      <Nav className="me-auto">{os}</Nav>

      <TopLine />
      { (company != "") ? <SelectSite siteList={siteList} setSite={setSite} currentSite={site}/> : null }
      <TopLine />


      <OverlayTrigger trigger="click" placement="bottom" overlay={notifyMenu}>
      <Button fluid className="topbarIconButton" >
      <Image fluid src='icons/bell.svg' style={s.topicon}/> </Button>
      </OverlayTrigger>
      <TopLine />
      <OverlayTrigger trigger="click" placement="bottom" overlay={helpMenu}>
      <Button fluid className="topbarIconButton" >
      <Image fluid src='icons/question-circle-grey.svg' style={s.topicon}/> </Button>
      </OverlayTrigger>

      <TopLine />
      <Dropdown align="end">
      <Dropdown.Toggle className="topbarIconButton" variant="dark" id="dropdown-app">
      <Image  src='icons/person-circle.svg' style={s.topicon}></Image>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>  
          <h4>Profile</h4>
          Choose the company you want to work in
          <SelectCompany companyList={secProfile.allowedCompanies} setCompany={setCompany} currentCompany={company}/>
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Header>
          <h5> Current signed in details</h5>
          <p>User: {secProfile.authUserName}</p>
          <p>Client: {secProfile.authClientName}</p>
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item>
          <SignOutButton text="Sign Out"/> 
        </Dropdown.Item>  
      </Dropdown.Menu>
    </Dropdown>

    </Container>

  </Navbar> 
  );
}
export default Topbar;
