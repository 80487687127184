import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';

function DashPerc(props){

    let perc1 =  (props.data.remaining /
                (props.data.remaining + props.data.done))

    let perc = 100 * perc1            
    return(
        <Table>
        <tr>
            <td rowspan="2">{perc}</td>
            <td>  {props.data.remaining}</td>
            </tr><tr>
            <td> {props.data.done + props.data.remaining}</td>
        </tr>
        </Table>    
    )
}
function DashText(props){
    return(
        props.text
    )    
}


function Dashlette(props){
    let sTitle = true
    if ("ShowTitle" in props){
        if (props.ShowTitle.toUpperCase() == "FALSE") {
            sTitle = false
        }
    }

    return (

    <Card border="primary" style={{ width: '18rem' }}>
        <Card.Title>
            {sTitle ? props.title : null} 
        </Card.Title>
        <Card.Body>
            {(props.type === "Text") ? <DashText text={props.text}/>: null }
            {(props.type === "Percent") ? <DashPerc data={props.data}/>: null}
        </Card.Body>
    </Card>
    )
}

export default Dashlette
