import Dashlette from "./Dashlette";
import Table from 'react-bootstrap/Table';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function DashView(props){

    let data1 = {
        "done":30,
        "remaining":60
    }

    let data2 = {
        "done":30,
        "remaining":70
    }

    return (
    <>
    <Container fluid>
        
        <Row>
        <Col xs={1}/>
        <Col xs={10}>
        <Table> 
        <tr>   
        <td>
        <Dashlette type="Text" title="Text 1" text="This is a text card"/></td>
        <td>
        <Dashlette type="Percent" title="A Pecentage Rate" data={data1}/>
        </td>
        <td>
        <Dashlette type="Text" title="Text 2" text="This is a text card"/></td>
        <td>
        <Dashlette type="Percent" ShowTitle="false" title="A Pecentage Rate 2" data={data2}/>
        </td>

        </tr>
        </Table>
        </Col>
        <Col xs={1}/>
        </Row>
    </Container>
    </>
    )
}
