

import WhsePlanCtl from './WhsePlanCtl';
import KpiTimeCtl from './KpiTimeCtl';
import KpiConfigCtl from './KpiConfigCtl';
import GridCtl from './GridCtl';
import CrudCtl from './CrudCtl';
import { DashView } from './DashView';
import {CTDCView} from './CTDCView';
function MainApp(props) {

 let cs = ""

 switch(props.fun) {

    case "dashView": 
      cs = <DashView fwhs={props.fwhs} cpy={props.cpy} />
    break;
    case "CTDCIn": 
      cs = <CTDCView fwhs={props.fwhs} cpy={props.cpy} view="In"  />
    break;
    case "CTDCOut": 
      cs = <CTDCView fwhs={props.fwhs} cpy={props.cpy} view="Out"  />
    break;
    case "CTDCUtil": 
      cs = <CTDCView fwhs={props.fwhs} cpy={props.cpy} view="Util"  />
    break;

    case "whsePlan": 
      cs = <WhsePlanCtl fwhs={props.fwhs} cpy={props.cpy} />
      break;
  case "MetricTime":
      cs = <KpiTimeCtl/>
      break;
  case "MetricSetUp":
      cs = <KpiConfigCtl fun={props.fun}></KpiConfigCtl>
      break;
  case "metricSetUp":
      cs = <KpiConfigCtl fun={props.fun}></KpiConfigCtl>
      break;
  case "prodDevStatus":
        cs = <GridCtl fun={props.fun} apps={props.apps}> </GridCtl>
        break;
  case "OptArea":
        cs = <CrudCtl fun={props.fun} apps={props.apps}> </CrudCtl>      
        break;
  case "OptLoc":
        cs = <CrudCtl fun={props.fun} apps={props.apps}> </CrudCtl>      
        break;
  case "MainCrud":
        cs = <CrudCtl fun={props.fun} apps={props.apps}> </CrudCtl>      
        break;        
    }


return (
    <div>
      <br></br>
      <br></br>
    {cs}
    </div>
  );
}

export default MainApp;
