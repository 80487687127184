
import React, {useState, useEffect, MouseEvent } from "react"

import { DataGrid } from '@mui/x-data-grid';


 export function  UiGrid( props) {

  let [sRows, setRows] = useState([]);
  let [sFunc, setFunc] = useState(props.func);


  
  useEffect(() => {
      setRows(props.rows);

  },[props.rows]);

  let tableHeight = 6000; 

  if (props.height == undefined) {
     tableHeight = 6000
   }
   else {
     tableHeight = props.height
   }


   return ( 
      <div style={{ height: 600, width: '70%' }}>
          <DataGrid  
            columns={props.cols} 
            rows={sRows} 

      />
      </div>
    );
};

