import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
function  SignOutButton(props) {
    const { instance } = useMsal();
    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogout(instance)}>{props.text}</Button>
    );
}

export default SignOutButton;
