import { Polygon } from "recharts";

function Tag(props) {


  const pointCords = "0,18 25,0 80,0 80,38 25,38" 
  const s = {tagText: {fill:"white",stroke:"#000000", fontSize:"8em",fontWeight:"950" }}
  let xStart = 0
  if (props.text.length >= 4 ) {
        xStart = 25
  } 
  else {
    xStart = 35
  }

  return (
    <svg  key="tag" width="40" height="20"  viewBox="0 0 80 40">
      <polygon
        points={pointCords}
        stroke={props.fillColor}
        fill={props.fillColor}
        stroke-width="1" > 
      </polygon>

      <polygon
        points={pointCords}
        stroke={props.strokeColor}
        fill="none"
        stroke-width="2" > 
      </polygon>
      <text
         x={xStart} y="23" class={s.tagText} >
        {props.text}
      </text>

      </svg>
  );
}

export default Tag;