

import {useState, useEffect} from 'react';
import Select from 'react-select';
import Image from 'react-bootstrap/Image';

function getKpiObj(n,kl){ 
  let currKPI = {}
  kl.map((k) => {
    if (k.KPI_IDENTIFIER == n){
        currKPI=k
    }
  })
  return currKPI
}

function FmtOption(props) {

  const il = props.tags.map((tag)=> {
    <img src={'icons/'+tag.icon} width="20" height="20" />
    }
  )
  return (
    <>
    {il}
    {props.label}
    </>
  )
}


function KpiSelect(props) {
  const [kpi,setKpi] = useState("")
  const tt = (m) =>
  {
    setKpi(m);
    console.log("m=",m) 
    props.sk(m.value)
  } 
 
  console.log("kpi=",kpi)

  let z=[]
  props.kpilist.map((n)=> {
    let o={}
    o.value = n.KPI_IDENTIFIER.toString()
    o.lab = n.KPI_NAME
    o.tag1 = ""
    o.tag2 = ""
    if (n.tags.length > 0) {
      o.tag1 = n.tags[0]
    }
    if (n.tags.length > 1) {
      o.tag2 = n.tags[1]
    }

    z.push(o)
  });
  
  return (
    <Select 
      options={z} 
      formatOptionLabel={kpi => (
        <div>
          <span>{kpi.lab}</span>
          { (kpi.tag1 != "") ? <img src={'icons/vert-line.svg'} height="20" width="20" /> : null }
          { (kpi.tag1 != "") ? <img src={'icons/'+kpi.tag1.icon} height="20" width="20" /> : null }
          { (kpi.tag2 != "") ? <img src={'icons/vert-line.svg'} height="20" width="20" /> : null }
          { (kpi.tag2 != "") ? <img src={'icons/'+kpi.tag2.icon} height="20" width="20" /> : null }
        </div>
      )}
      onChange={tt} 
      />
  );
}


export default KpiSelect;
