import { Container } from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export function CTDCView(props){
    const script = document.createElement('script');
    script.src = "https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
    script.async = true;  
    script.type = "module"
    document.body.appendChild(script);

    return (
    <>
 
    <Container fluid>
        
        <Row>
        <Col xs={1}/>
        <Col xs={10}>
            Control Tower - {props.view}
            <tableau-viz id='tableau-viz' 
            src='https://prod-useast-b.online.tableau.com/t/svctech/views/RouteDashboard/Route1/a857ad58-680a-4751-ae51-25e8f61092f1/c50d24d3-9bfc-4f06-a2db-800f8177a613' 

            width='1000' height='840' hide-tabs toolbar='bottom' >

            </tableau-viz>
        </Col>
        <Col xs={1}/>
        </Row>
    </Container>
    </>
    )
}
