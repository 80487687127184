export const msalConfig = {
    auth: {
      clientId: "7c82e87f-d52d-4bad-9bf4-b1cd6de33f72",
      authority: "https://login.microsoftonline.com/common", 
      redirectUri: process.env.REACT_APP_DCI_SERVER_URL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com"
  };