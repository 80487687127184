
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Popover from 'react-bootstrap/Popover';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Overlay from 'react-bootstrap/Overlay';
import Card from 'react-bootstrap/Card';
import { UiCrudGrid } from './UiCrudGrid';
import React, { useState,  useEffect, StrictMode, useRef } from "react";
import { gridColumnLookupSelector, gridColumnsTotalWidthSelector } from '@mui/x-data-grid';

let url_server = process.env.REACT_APP_DCI_UTIL_URL 
if (process.env.REACT_APP_DCI_UTIL_PORT != "80") {
  url_server = url_server + ":" + process.env.REACT_APP_DCI_UTIL_PORT
}
 



async function loadEntityDefn(entity, setDefn, setRedraw) {
// loads a list of entities into the data crid
// smr == function that is told false when loading and true when loaded
// smd == function that is passed the result of the api lookup
  
  const url = url_server + "/crudAPI/1/funcdefn?Function=" + entity

  console.log("** url = ", url)

  let response = await fetch(url,
      {
      method: "GET",
      mode: "cors",
      headers: {
        "Accept": "application/json",
}
      });
  let rsp = await response.json();
  setDefn(rsp);
  setRedraw(true)

};

async function loadEntityList(entity, setList, showGrid) {
  // loads a warehouse plan with the designated higlighting
  // smr == function that is told false when loading and true when loaded
  // smd == function that is passed the result of the api lookup
    
    const url = url_server + "/crudAPI/1/EntityList" + "?Entity=" + entity 
  
    let response = await fetch(url,
        {
        method: "GET",
        mode: "cors",
        headers: {
          "Accept": "application/json",
}
        });
    let rsp = await response.json();
    setList(rsp);
    showGrid(true)
    console.log("list= ",rsp)
  };
  


  async function entityPut(tableName, cpy, site, formValues, setRsp) {
    // loads a warehouse plan with the designated higlighting
    // smr == function that is told false when loading and true when loaded
    // smd == function that is passed the result of the api lookup
    console.log("Form = ", formValues)
    console.log("Table = ", tableName)
    console.log("cpy = ", cpy)
    console.log("site = ", site)

      let url = url_server + "/crudAPI/1/Entity" + "?Entity=" 
                + tableName 
                + "&CPY_CD=" + cpy + "&SITE=" +site  
      Object.keys(formValues).forEach(key => {
        if (formValues[key] != "" ) {
          const s =  "&" + key + "=" + formValues[key]
          console.log("sskk=",s,key)
          url = url + s.toString()
        }
      })
      let response = await fetch(url,
          {
          method: "PUT",
          mode: "cors",
          headers: {
            "Accept": "application/json",
}
          });
      let rsp = await response.json();
      console.log("putresp= ",rsp)
      setRsp(rsp)
    };
  


function EntityDetail(props) {
  console.log("detail = ", props.detail)
  return (
    <>
    <Card>
      <Card.Body>
        <Card.Title>{props.detail.ENTITY_NAME}</Card.Title>
      <br></br>
        <Table>
        <tbody>
        <tr>
          <td>Metric Identifier</td><td>{props.detail.KPI_IDENTIFIER}</td></tr>
        <tr><td>Dimension Name</td><td>{props.detail.KPI_DIM01_NAME}</td></tr>
        </tbody>
        </Table> 
    <br></br>  
    <Tabs>
      <Tab eventKey= "Detail" title="Detail">
      <br></br>
      </Tab>
    </Tabs>
    </Card.Body>
    </Card>
    </>
) }

function AddDetail(props) {
  const [sFormFields,setFormFields] = useState({});
  const [sFormResponse,setFormResponse] = useState([]);
  const [sFormReset,setFormReset] = useState(false);
  const updateFormValue = (a,v) => 
  {
    let ts = sFormFields;
    ts[a] = v;
    setFormFields(ts);
    setFormResponse([])
  }
  const resetFormValues = () => 
  {
    let ts = {}
    Object.keys(sFormFields).forEach(key => {
      ts[key]=""
    })
    setFormFields(ts);
  }

  useEffect(() => {
   if (sFormResponse.length > 0) {
   if (sFormResponse[0].Code == "0000")  {
     resetFormValues()
     setFormReset(true)
     props.reDraw()
   }}
  },[sFormResponse])

  console.log("funcDefnl = ", props.funcDefn)
  return (
  <Overlay  target={props.target} show={true} placement="left" container={props.container}> 
  <Popover id="popover-kpiinfo">
  <Popover.Header>
  <Table>
  <tr> <td><h3>Add New</h3></td>
  <td align="right"><Button  variant = 'outline-light' onClick={ ((e) => { props.setAdd(false)}) }>
      <Image src='icons/close.svg'></Image>
      </Button>
      </td></tr></Table>    
  </Popover.Header>
  <Popover.Body>
    <Form>
    <Table  borderless={true}>
      <tbody>
       {props.funcDefn.columns.map((c) => 
          <><tr><td><p>{c.label}</p> </td>
           <td><FormControl aria-label={c.columnName} 
                value = {sFormFields[c.columnName]}
                key = {c.columnName}
                onChange = {(e) => updateFormValue(c.columnName,e.target.value)}
           /></td></tr></>
          )}
      </tbody>    
    </Table>
    <Button variant="outline-light" onClick={ ((e) => { entityPut(props.funcDefn.TableName,"MRPCO","110",sFormFields,setFormResponse)})} >
      <Image src='icons/save.svg'></Image>
  </Button>
    <Table borderless={true}>
      <tbody>
      {
        sFormResponse.map((r) =>
        <tr><td>{r.Text}</td></tr>
        )
      }
      </tbody>
    </Table>    
    </Form>

  </Popover.Body>
  </Popover></Overlay>
) }



function CrudCtl(props) {
  const [sEntity,setEntity] = useState(props.fun);
  const [sFuncDefn,setFuncDefn] = useState("WAIT");
  const [sEntityList,setEntityList] = useState("WAIT");
  const [sNewRow,setNewRow] = useState({})

  const [showGrid,setShowGrid] = useState(false);
  const [showDetail,setShowDetail] = useState(false);
  const [firstTime, setFirstTime] = useState(true)
  const [sDetailKey, setDetailKey] = useState("")
  const [sDetail,setDetail] = useState("");
  const [sAdd,setAdd] = useState(false);
  const [sAddTarg,setAddTarg] = useState("");
  const [sRedrawTable,setRedrawTable] = useState(true);
  const [sTableRows,setTableRows] = useState([]);
  const [sTableCols,setTableCols] = useState([]);
  const ref=useRef(null)

  const s= {topicon: {width:"18px", height:"18px"},
            addicon: {width:"25px", height:"25px"}}

  const sel = (q) => { {setEntityList(q); srl(true); console.log("setting EntityList",q)} }
  const srl = (q) => { { setShowGrid(q); console.log("setting EntityList Render Flag",q)}}
  const chtyp = (q) => { { console.log("Pressed the button ",q)}}
  const setRedraw = (t) => {{sel(t); setRedrawTable(!sRedrawTable); setShowGrid(true)}} 

  console.log("fun =", props.fun)
  console.log("sEntity =", sEntity)
  if (props.fun != sEntity) {
    setEntity(props.fun)
  }
  useEffect(() => {
    console.log("Setting Entity",props.fun)
    setEntity(props.fun)
    if (sEntity != "") {
       loadEntityDefn(sEntity,setFuncDefn,setRedrawTable);

    } 
  },[sEntity])

  // get list of Entities
  useEffect(() => {
        loadEntityList(sEntity,setRedraw,setShowGrid)
  },[sFuncDefn])


  useEffect(() => {
    console.log("detail key = ", sDetailKey)
  },[sDetailKey])


  useEffect(() => {
    if (sFuncDefn != "WAIT") {
      console.log("sFuncDefn =", sFuncDefn)  
      console.log("redraw = ",sRedrawTable)
      console.log("showGrid = ", showGrid)  
      console.log("llist = ", sEntityList)
      if (showGrid == true ) {
        let tableCols = [];
        let tableRows=[];
        sFuncDefn.columns.map((c)=>{
          let co = {};
          co.field = c.columnName
          co.headerName = c.label
          co.width = c.width
          tableCols.push(co)
        })
        sEntityList.map((k,i) => {
          let o={}
          console.log("EL=",sEntityList)
            sFuncDefn.columns.map((c)=>{
              o[c.columnName]=k[c.columnName]
            })
          o.id = i * 100
          tableRows.push(o)
          console.log("o=",o)
        }) 

        setTableCols(tableCols)
        setTableRows(tableRows)
        setShowGrid(true)
        setRedrawTable(false)
      }
    }
     
},[sRedrawTable,sEntityList,sFuncDefn,showGrid])


  return (  
    <div>
      <Container >
      <Row>
      <Col md={6}>
      <h2>{sFuncDefn.functionTitle}</h2>      
      </Col>
      <Col md={6}>
      <Button  variant = 'outline-light' > <Image src='icons/download.svg'></Image>  </Button>      
      <Button  variant = 'outline-light' > <Image src='icons/file_upload.svg'></Image>  </Button>    
      <Button  variant = 'outline-light' onClick={ ((e) => { setAdd(!sAdd); setAddTarg(e.target);  console.log('add=',sAdd)}) }>
      <Image src='icons/add_circle.svg'></Image>
      </Button>
      </Col>
      </Row>
      <Row>

      <Col md={6}>
      </Col>
      <Col md={2}>
      </Col>
      <Col md={2}>
      </Col>
      </Row>
      <Row >
      <Col md={7}>
      { showGrid ? <><br></br><UiCrudGrid   cols={sTableCols} rows={sTableRows}></UiCrudGrid></> : null}
      <div ref = {ref}>{ sAdd ? <AddDetail target={sAddTarg} container={ref} setAdd={setAdd} funcDefn={sFuncDefn} reDraw={setRedrawTable}/> : null }</div>
      </Col>
      <Col md={5}>
        <br></br> 
      { showDetail ? <EntityDetail detail={sDetail[0]}></EntityDetail> : null}
      </Col>
      </Row>
      </Container>
   </div> 
  );
}

export default CrudCtl;
