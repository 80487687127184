

import {useState, useEffect} from 'react';



function MapPosition(props) {
  const [sHover,setHover] = useState(false)
  
  const setHover2 = (a) => {
    setHover(a);
    console.log("hover:",a);
  //   props.reRender();
  }

  let keyvalue = props.aisle + props.bay;
  const tip = keyvalue + " " + props.y + " " + props.x

  let cellColor = props.defaultColor
  let hoverFontColor = "white"

  if (props.data !== undefined ) {
    if (props.data.category !== undefined ){
      cellColor = props.color[props.data.category]
      if (props.data.category >= "4" && props.data.category <= "7" ) {
        hoverFontColor = props.defaultColor
      }
   }
  }
  
  



  const styles = {
    plain: { fill: cellColor },
    svghoverr: { fill: cellColor,
                position:"absolute" },
    svghovert: { fill: hoverFontColor,
                 fontStyle: "bold",
                position:"absolute" }
    
  }
  const xcord = parseFloat(props.x)
  const ycord = parseFloat(props.y)
  // console.log("key=",keyvalue,"x=",props.x,"y=",props.y)


  return (
    <g>
    <rect key={keyvalue} width="8" height="8"  x={props.x} y={props.y} style={styles.plain}
      onMouseOver={() => setHover2(true)} onMouseOut={() => setHover2(false)} onClick={() => setHover2(true)}>
    </rect>
    { sHover ? <g> <rect x={props.x+15} y={props.y-11} height="25" width="60" style={styles.svghoverr} /> 
              <text x={props.x+15} y={props.y+7} textLength="60" lengthAdjust="spacingAndGlyphs" style={styles.svghovert} >{keyvalue} </text>  
               </g> : null }
    </g>
 
  );
}


export default MapPosition;
